import React, { Component } from 'react';
import './App.css';

import Protect from 'react-app-protect';
import 'react-app-protect/dist/index.css';
import './assets/css/app-protect-override.css';

import FormContainer from './components/form/form.component';

class App extends Component {
  render() {
    return (
      <Protect
        sha512="518a42304cd7a6724323b2d3ef33aef9bb2c35f6a82eb0918b728c587357826bd41ef6193406d87c1eef5480c98405d707d178d4aaead0c5d080a68f8d6c9747"
        boxTitle="This app is password protected"
        inputPlaceholder="Enter Password"
        buttonLabel="Send"
      >
        <div className="App">
          <FormContainer />
        </div>
      </Protect>
    );
  }
}

export default App;
