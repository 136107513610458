import React, { Component } from "react";
import io from 'socket.io-client';

// Import FileUpload
import FileUpload from '../file-upload/file-upload.component';

// Material UI
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postId: 0,
      psu_1_status: false,
      psu_1_voltage: 0,
      psu_1_current: 0,
      psu_2_status: false,
      psu_2_voltage: 0,
      psu_2_current: 0,
      Sine: false,
      Square: false,
      Sawtooth: false,
      Arb: false,
      Input: false,
      Output: false,
      Relay1: false,
      Relay2: false,
      Relay3: false,
      Relay4: false,
      Relay5: false,
      Relay6: false,
      Relay7: false,
      Relay8: false,
      Relay9: false,
      Relay10: false,
      Relay11: false,
      Relay12: false,
      Relay13: false,
      Relay14: false,
      Relay15: false,
      v_peak_to_peak: 0,
      v_offset: 0,
      x_div: 0,
      y_div: 0,
      trigger: 0,
      postBoard: []
    };
  }

  componentWillUnmount() {
    this.socket.close()
    console.log("Component unmounted")
  }
  componentDidMount() {
    var socketEndpoint = "https://socket.xbench.app"
        this.socket = io.connect(socketEndpoint, {
        reconnection: true,
        // transports: ['websocket']
    });
    console.log("component mounted")
    this.socket.on("connect", message => {
        console.log("Client response - Client connected")
    })

    this.socket.on("serverMsg", message => {
      console.log(message)
    })

    this.socket.on("appendBoard", message => {
      this.setState({
        postBoard: this.state.postBoard.concat({
          message
        })
      })
      console.log(this.state.postBoard)
    })
  }

  // handleInputChange(event) {
  //   const target = event.target;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   const name = target.name;
  //
  //   this.setState({
  //     [name]: value
  //   });
  // }

  handleEmit = () => {
    this.socket.emit("clientMsg", {
      'psu_1_status': this.state.psu_1_status,
      'psu_1_voltage': this.state.psu_1_voltage,
      'psu_1_current': this.state.psu_1_current,
      'psu_2_status': this.state.psu_2_status,
      'psu_2_voltage': this.state.psu_2_voltage,
      'psu_2_current': this.state.psu_2_current,
      'Sine': this.state.Sine,
      'Square': this.state.Square,
      'Sawtooth': this.state.Sawtooth,
      'Arb': this.state.Arb,
      'Input': this.state.Input,
      'Output': this.state.Output,
      'Relay1': this.state.Relay1,
      'Relay2': this.state.Relay2,
      'Relay3': this.state.Relay3,
      'Relay4': this.state.Relay4,
      'Relay5': this.state.Relay5,
      'Relay6': this.state.Relay6,
      'Relay7': this.state.Relay7,
      'Relay8': this.state.Relay8,
      'Relay9': this.state.Relay9,
      'Relay10': this.state.Relay10,
      'Relay11': this.state.Relay11,
      'Relay12': this.state.Relay12,
      'Relay13': this.state.Relay13,
      'Relay14': this.state.Relay14,
      'Relay15': this.state.Relay15,
      'v_peak_to_peak': this.state.v_peak_to_peak,
      'v_offset': this.state.v_offset,
      'x_div': this.state.x_div,
      'y_div': this.state.y_div,
      'trigger': this.state.trigger,
    })

    console.log("Emit Clicked")
  }

  handlePSU1StatusChange(e) {
    this.setState(({ psu_1_status }) => ({ psu_1_status: !psu_1_status }));
  }
  handlePSU2StatusChange(e) {
    this.setState(({ psu_2_status }) => ({ psu_2_status: !psu_2_status }));
  }
  handlePSU1VChange(e) {
    this.setState({ psu_1_voltage: e.target.value });
  }
  handlePSU1CChange(e) {
    this.setState({ psu_1_current: e.target.value });
  }
  handlePSU2VChange(e) {
    this.setState({ psu_2_voltage: e.target.value });
  }
  handlePSU2CChange(e) {
    this.setState({ psu_2_current: e.target.value });
  }
  handleRelay1Change(e) {
    this.setState(({ Relay1 }) => ({ Relay1: !Relay1 }));
  }
  handleRelay2Change(e) {
    this.setState(({ Relay2 }) => ({ Relay2: !Relay2 }));
  }
  handleRelay3Change(e) {
    this.setState(({ Relay3 }) => ({ Relay3: !Relay3 }));
  }
  handleRelay4Change(e) {
    this.setState(({ Relay4 }) => ({ Relay4: !Relay4 }));
  }
  handleRelay5Change(e) {
    this.setState(({ Relay5 }) => ({ Relay5: !Relay5 }));
  }
  handleRelay6Change(e) {
    this.setState(({ Relay6 }) => ({ Relay6: !Relay6 }));
  }
  handleRelay7Change(e) {
    this.setState(({ Relay7 }) => ({ Relay7: !Relay7 }));
  }
  handleRelay8Change(e) {
    this.setState(({ Relay8 }) => ({ Relay8: !Relay8 }));
  }
  handleRelay9Change(e) {
    this.setState(({ Relay9 }) => ({ Relay9: !Relay9 }));
  }
  handleRelay10Change(e) {
    this.setState(({ Relay10 }) => ({ Relay10: !Relay10 }));
  }
  handleRelay11Change(e) {
    this.setState(({ Relay11 }) => ({ Relay11: !Relay11 }));
  }
  handleRelay12Change(e) {
    this.setState(({ Relay12 }) => ({ Relay12: !Relay12 }));
  }
  handleRelay13Change(e) {
    this.setState(({ Relay13 }) => ({ Relay13: !Relay13 }));
  }
  handleRelay14Change(e) {
    this.setState(({ Relay14 }) => ({ Relay14: !Relay14 }));
  }
  handleRelay15Change(e) {
    this.setState(({ Relay15 }) => ({ Relay15: !Relay15 }));
  }
  handleSineChange(e) {
    this.setState(({ Sine }) => ({ Sine: !Sine }));
  }
  handleSquareChange(e) {
    this.setState(({ Square }) => ({ Square: !Square }));
  }
  handleSawtoothChange(e) {
    this.setState(({ Sawtooth }) => ({ Sawtooth: !Sawtooth }));
  }
  handleArbChange(e) {
    this.setState(({ Arb }) => ({ Arb: !Arb }));
  }
  handleInputChange(e) {
    this.setState(({ Input }) => ({ Input: !Input }));
  }
  handleOutputChange(e) {
    this.setState(({ Output }) => ({ Output: !Output }));
  }
  handleVPeakChange(e) {
    this.setState({ v_peak_to_peak: e.target.value });
  }
  handleVOffsetChange(e) {
    this.setState({ v_offset: e.target.value });
  }
  handleTriggerChange(e) {
    this.setState({ trigger: e.target.value });
  }
  handleXdivChange(e) {
    this.setState({ x_div: e.target.value });
  }
  handleYdivChange(e) {
    this.setState({ y_div: e.target.value });
  }

  clearForm = () => {
    this.setState({
      postId: 0,
      psu_1_status: false,
      psu_1_voltage: 0,
      psu_1_current: 0,
      psu_2_status: false,
      psu_2_voltage: 0,
      psu_2_current: 0,
      Sine: false,
      Square: false,
      Sawtooth: false,
      Arb: false,
      Input: false,
      Output: false,
      Relay1: false,
      Relay2: false,
      Relay3: false,
      Relay4: false,
      Relay5: false,
      Relay6: false,
      Relay7: false,
      Relay8: false,
      Relay9: false,
      Relay10: false,
      Relay11: false,
      Relay12: false,
      Relay13: false,
      Relay14: false,
      Relay15: false,
      v_peak_to_peak: 0,
      v_offset: 0,
      x_div: 0,
      y_div: 0,
      trigger: 0,
      postBoard: []
    });
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <img src="https://via.placeholder.com/600x400" alt="" />
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <FormControl component="fieldset" className="" id="relay-group">
                    <FormLabel component="legend">Power Supplies</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="psu-1"
                            checked={this.state.psu_1_status}
                            onChange={this.handlePSU1StatusChange.bind(this)}
                          />
                        }
                        label="PSU 1"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="psu-2"
                            checked={this.state.psu_2_status}
                            onChange={this.handlePSU2StatusChange.bind(this)}
                          />
                        }
                        label="PSU 2"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset" className="">
                    <FormLabel component="legend">PSU 1</FormLabel>
                    <FormGroup>
                      <TextField label="Voltage" variant="outlined" color="primary"
                        type="number"
                        min="0"
                        max="40"
                        step="0.01"
                        id="psu_1_voltage"
                        value={this.state.psu_1_voltage}
                        onChange={this.handlePSU1VChange.bind(this)}
                        placeholder={this.state.psu_1_voltage.toString()}
                      />
                      <TextField label="Current" variant="outlined" color="primary"
                          type="number"
                          min="0"
                          max="40"
                          step="0.01"
                          id="psu_1_current"
                          value={this.state.psu_1_current}
                          onChange={this.handlePSU1CChange.bind(this)}
                          placeholder={this.state.psu_1_current.toString()}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset" className="">
                    <FormLabel component="legend">PSU2</FormLabel>
                    <FormGroup>
                      <TextField label="Voltage" variant="outlined" color="primary"
                          type="number"
                          min="0"
                          max="40"
                          step="0.01"
                          id="psu_2_voltage"
                          value={this.state.psu_2_voltage}
                          onChange={this.handlePSU2VChange.bind(this)}
                          placeholder={this.state.psu_2_voltage.toString()}
                      />
                      <TextField label="Current" variant="outlined" color="primary"
                          type="number"
                          min="0"
                          max="40"
                          step="0.01"
                          id="psu_2_current"
                          value={this.state.psu_2_current}
                          onChange={this.handlePSU2CChange.bind(this)}
                          placeholder={this.state.psu_2_current.toString()}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary"
                  type="button"
                  className="btn btn-success btn-block"
                  id="sendButton"
                  onClick={this.handleEmit}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <img src="https://via.placeholder.com/600x400" alt="" />
            </Grid>
            <Grid item>
              <FormControl component="fieldset" className="" id="relay-group">
                <FormLabel component="legend">Relays</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-1"
                        checked={this.state.Relay1}
                        onChange={this.handleRelay1Change.bind(this)}
                      />
                    }
                    label="Relay 1"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-2"
                        checked={this.state.Relay2}
                        onChange={this.handleRelay2Change.bind(this)}
                      />
                    }
                    label="Relay 2"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-3"
                        checked={this.state.Relay3}
                        onChange={this.handleRelay3Change.bind(this)}
                      />
                    }
                    label="Relay 3"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-4"
                        checked={this.state.Relay4}
                        onChange={this.handleRelay4Change.bind(this)}
                      />
                    }
                    label="Relay 4"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-5"
                        checked={this.state.Relay5}
                        onChange={this.handleRelay5Change.bind(this)}
                      />
                    }
                    label="Relay 5"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-6"
                        checked={this.state.Relay6}
                        onChange={this.handleRelay6Change.bind(this)}
                      />
                    }
                    label="Relay 6"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-7"
                        checked={this.state.Relay7}
                        onChange={this.handleRelay7Change.bind(this)}
                      />
                    }
                    label="Relay 7"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-8"
                        checked={this.state.Relay8}
                        onChange={this.handleRelay8Change.bind(this)}
                      />
                    }
                    label="Relay 8"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-9"
                        checked={this.state.Relay9}
                        onChange={this.handleRelay9Change.bind(this)}
                      />
                    }
                    label="Relay 9"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-10"
                        checked={this.state.Relay10}
                        onChange={this.handleRelay10Change.bind(this)}
                      />
                    }
                    label="Relay 10"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-11"
                        checked={this.state.Relay11}
                        onChange={this.handleRelay11Change.bind(this)}
                      />
                    }
                    label="Relay 11"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="relay-12"
                        checked={this.state.Relay12}
                        onChange={this.handleRelay12Change.bind(this)}
                      />
                    }
                    label="Relay 12"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <Grid container direction="column">
          <Grid item>
            <Grid container>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">FGEN</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sine"
                          checked={this.state.Sine}
                          onChange={this.handleSineChange.bind(this)}
                        />
                      }
                      label="Sine"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="square"
                          checked={this.state.Square}
                          onChange={this.handleSquareChange.bind(this)}
                        />
                      }
                      label="Square"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sawtooth"
                          checked={this.state.Sawtooth}
                          onChange={this.handleSawtoothChange.bind(this)}
                        />
                      }
                      label="Sawtooth"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="arb"
                          checked={this.state.Arb}
                          onChange={this.handleArbChange.bind(this)}
                        />
                      }
                      label="Arb"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className="">
                  <FormLabel component="legend"></FormLabel>
                  <FormGroup>
                    <TextField label="Trigger" variant="outlined" color="primary"
                        type="number"
                        min="0"
                        max="40"
                        step="0.01"
                        id="trigger"
                        value={this.state.trigger}
                        onChange={this.handleTriggerChange.bind(this)}
                        placeholder={this.state.trigger.toString()}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" className="">
                  <FormLabel component="legend">I/O</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="input"
                          checked={this.state.Input}
                          onChange={this.handleInputChange.bind(this)}
                        />
                      }
                      label="Input"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="output"
                          checked={this.state.Output}
                          onChange={this.handleOutputChange.bind(this)}
                        />
                      }
                      label="Output"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className="">
                  <FormLabel component="legend"></FormLabel>
                  <FormGroup>
                    <TextField label="V Peak to Peak" variant="outlined" color="primary"
                        type="number"
                        min="0"
                        max="40"
                        step="0.01"
                        id="v_peak_to_peak"
                        value={this.state.v_peak_to_peak}
                        onChange={this.handleVPeakChange.bind(this)}
                        placeholder={this.state.v_peak_to_peak.toString()}
                    />
                    <TextField label="V Offset" variant="outlined" color="primary"
                        type="number"
                        min="0"
                        max="40"
                        step="0.01"
                        id="v_offset"
                        value={this.state.v_offset}
                        onChange={this.handleVOffsetChange.bind(this)}
                        placeholder={this.state.v_offset.toString()}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl component="fieldset" className="uploadBtn">
              <FormLabel component="legend">Arb File</FormLabel>
              <FormGroup>
                  <FileUpload />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component="fieldset" variant="outlined">
                  <FormLabel component="legend">Xdiv</FormLabel>
                  <Select
                    native
                    value={this.state.x_div}
                    onChange={this.handleXdivChange.bind(this)}
                    label="Xdiv"
                    inputProps={{
                      name: 'x-div',
                      id: 'x-div',
                    }}
                  >
                    <option value="Select One">Select</option>
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" variant="outlined">
                  <FormLabel component="legend">Ydiv</FormLabel>
                  <Select
                    native
                    value={this.state.y_div}
                    onChange={this.handleYdivChange.bind(this)}
                    label="Ydiv"
                    inputProps={{
                      name: 'y-div',
                      id: 'y-div',
                    }}
                  >
                    <option value="Select One">Select</option>
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary"
                type="button"
                className="btn btn-success btn-block"
                id="resetButton"
                onClick={this.clearForm}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div id="msgBoard">
            <h2>PSU-1 Status: {this.state.psu_1_status}</h2>
            <h2>PSU-2 Status: {this.state.psu_2_status}</h2>
            <p>Logs (returned from socketserver):</p>
            <ul className="list-group list-group-flush" id="messages">
              {this.state.postBoard.map((post, idx) => (
                <li key={post.id}> {post.message} </li>
              ))}
            </ul>
          </div>
        </Grid>
      </Grid>
    </Grid>
    );
  }
}

export default FormContainer;
